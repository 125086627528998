<template>
  <div class="mine">
    <!-- <div class="mine-in">
      <div class="mine-head">
        <div class="mine-yh">
          <div class="mine-tx">
            <img
              v-if="!$store.state.common.userInfo"
              src="../assets/mine/avatar.png"
              alt=""
            />
            <img v-else :src="userInfo.headPortrait" alt="" />
          </div>
          <div class="mine-xx">
            <p class="mine-p" @click="showPopup">{{ userInfo.orgName }}</p>
            <p class="mine-p1">{{ userInfo.cractCode }}</p>
          </div>
        </div>
      </div>
      <div class="mine-content">
        <div class="mine-ct1">
          <p></p>
          <p style="width: 1.5rem; text-align: left">头像</p>
          <p>
            <van-field input-align="right" @click="uploadImg" />
          </p>
          <div class="mine-jt">
            <div></div>
          </div>
        </div>
        <div class="mine-ct2">
          <p></p>
          <p style="width: 1.5rem; text-align: left">微信昵称</p>
          <p>
            <van-field
              disabled
              v-model="userInfo.orgName"
              input-align="right"
            />
          </p>
          <div class="mine-jt">
            <div></div>
          </div>
        </div>
        <div class="mine-ct3">
          <p></p>
          <p style="width: 1.5rem; text-align: left">手机号</p>
          <p>
            <van-field
              disabled
              v-model="userInfo.cractMobile"
              placeholder="未绑定"
              input-align="right"
            />
          </p>
          <div class="mine-jt">
            <div></div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 手机号弹窗 -->
    <van-dialog v-model="show1" title="请输入手机号" show-cancel-button @cancel="show1 = false" @confirm="confirm">
      <template>
        <van-field style="margin: 0 15px" v-model="phone" rows="1" placeholder="请输入留手机号" />
      </template>
    </van-dialog>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { upload, getUserInfo, getToken } from '../api/home'
import { mapMutations } from 'vuex'
export default {
  //13858105116
  data() {
    return {
      show1: false,
      phone: '',
      userInfo: {
        orgName: '未登录',
        cractMobile: '未绑定',
        headPortrait: '@/assets/mine/avatar.png'
      }
    }
  },
  mounted() {
    this.showPopup()
  },
  methods: {
    ...mapMutations({
      setToken: 'common/setToken',
      setUser: 'common/setUser'
    }),

    showPopup() {
      let userInfo = this.$store.state.common.userInfo
      // console.log(userInfo)
      if (!userInfo) {
        this.show1 = true
      } else {
        this.userInfo = userInfo
        this.phone = this.userInfo.cractMobile
        this.confirm(false)
      }
    },

    async confirm(bol = true) {
      //  执行此方法时需要判断 当前用户是否已经登录过， 如果登录则取之前登录的时候好佛则 重新输入登录

      var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (this.phone.replace(/\s+/g, '').length == 0 || isNaN(Number(this.phone)) || !reg_tel.test(this.phone)) {
        Toast.fail('请输入正确手机号')
        return false
      }
      try {
        let that = this
        let p1 = await getToken({ sign: this.phone })
        that.setToken(p1.data)
        let p2 = await getUserInfo()
        let userInfo = (that.userInfo = p2.data)
        that.setUser(userInfo)
        if (bol) {
          Toast.success('登录成功')
        }
      } catch (err) {
        let that = this
        Toast({
          type: 'fail',
          message: '获取成功',
          onClose: () => {
            that.$router.go(-1)
          }
        })
      }
    },

    uploadImg() {
      document.activeElement.blur()
      if (!this.$store.state.common.userInfo) {
        Toast.fail('未登录')
        return false
      }
      let that = this
      let vInput = document.createElement('input')
      vInput.type = 'file'
      vInput.accept = 'image/png,image/jpg'
      vInput.click()
      vInput.onchange = function () {
        let formData = new FormData()
        formData.append('file', vInput.files[0])
        upload(formData)
          .then(res => {
            Toast.success({
              type: 'success',
              message: '操作成功',
              onClose: function () {
                that.userInfo.headPortrait = res.data
                // console.log('🚀 ~ file: mine.vue ~ line 69 ~ upload ~ res', res.data)
              }
            })
          })
          .catch(err => {
            Toast.fail('操作失败！')
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .van-dialog__header {
  padding-top: 16px;
}
::v-deep .van-cell {
  padding: 0;
  .van-field__control {
    height: rem(78);
    font-size: rem(28);
  }
}
.mine {
  width: 100%;
  background: #eeeeee;
  min-height: 100vh;
  .mine-head {
    width: 100%;
    height: rem(227);
    background: url('../assets/mine/bg.png') no-repeat 0 0;
    background-size: 100% 100%;
    padding-top: rem(68.5);
    padding-left: rem(39);
    .mine-yh {
      height: rem(90);
      width: rem(422);
      // background-color: red;
      display: flex;
      .mine-tx {
        width: rem(90);
        height: rem(90);
        img {
          width: 100%;
          height: 100%;
        }
        // background: url('') no-repeat 0 0;
        // background-size: 100% 100%;
        margin-right: rem(22);
      }
      .mine-xx {
        width: rem(332);
        height: rem(90);
        // background-color: blue;
        text-align: left;
        line-height: rem(35);
        padding-top: rem(5);
        .mine-p {
          font-size: rem(28);
          color: #ffffff;
        }
        .mine-p1 {
          font-size: rem(24);
          margin-top: rem(10);
          color: #ffffff;
        }
      }
    }
  }

  .mine-content {
    width: 100%;
    height: rem(234);
    background: #fff;
    padding-left: rem(39);
    .mine-ct1 {
      height: rem(78);
      // background: red;
      display: flex;
      p:first-child {
        width: rem(28);
        height: rem(28);
        margin-top: rem(24);
        margin-right: rem(32);
        background: url('../assets/mine/user.png') no-repeat 0 0;
        background-size: 100% 100%;
      }
      p:nth-child(2) {
        width: rem(56);
        height: rem(78);
        line-height: rem(78);
        font-size: rem(28);
        color: #333333;
        // background: yellow;
      }
      p:nth-child(3) {
        height: rem(78);
        width: rem(550);
        // background: black;
      }
      .mine-jt {
        height: rem(78);
        display: flex;
        align-items: center;
        div {
          width: rem(16);
          height: rem(16);
          border-top: rem(1) solid #cccccc;
          border-right: rem(1) solid #cccccc;
          transform: rotate(45deg);
          background: #ffffff;
        }
      }
    }
    .mine-ct2 {
      height: rem(78);
      // background: red;
      display: flex;
      p:first-child {
        width: rem(28);
        height: rem(28);
        margin-top: rem(24);
        margin-right: rem(32);
        background: url('../assets/mine/user1.png') no-repeat 0 0;
        background-size: 100% 100%;
      }
      p:nth-child(2) {
        width: rem(112);
        height: rem(78);
        line-height: rem(78);
        font-size: rem(28);
        color: #333333;
        // background: yellow;
      }
      p:nth-child(3) {
        height: rem(78);
        width: rem(493);
        // background: black;
      }
      .mine-jt {
        height: rem(78);
        display: flex;
        align-items: center;
        div {
          width: rem(16);
          height: rem(16);
          border-top: rem(1) solid #cccccc;
          border-right: rem(1) solid #cccccc;
          transform: rotate(45deg);
          background: #ffffff;
        }
      }
    }
    .mine-ct3 {
      height: rem(78);
      display: flex;
      p:first-child {
        width: rem(28);
        height: rem(28);
        margin-top: rem(24);
        margin-right: rem(32);
        background: url('../assets/mine/phone.png') no-repeat 0 0;
        background-size: 100% 100%;
      }
      p:nth-child(2) {
        width: rem(84);
        height: rem(78);
        line-height: rem(78);
        font-size: rem(28);
        color: #333333;
        // background: yellow;
      }
      p:nth-child(3) {
        height: rem(78);
        width: rem(521);
        // background: black;
      }
      .mine-jt {
        height: rem(78);
        display: flex;
        align-items: center;
        div {
          width: rem(16);
          height: rem(16);
          border-top: rem(1) solid #cccccc;
          border-right: rem(1) solid #cccccc;
          transform: rotate(45deg);
          background: #ffffff;
        }
      }
    }
  }
}
</style>
